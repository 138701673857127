@font-face {
  font-family: DINEngschrift;
  src: url('../assets/fonts/dinengschrift_1_.ttf');
}
@font-face {
  font-family: FZLTZHK;
  src: url('../assets/fonts/fzltzhgbk.ttf');
}
body {
  font-size: 14px;
  font-size: '微端雅黑';
  min-width: 1440px;
}
#root {
  width: 100%;
  height: 100%;
}

// 滚动条整体宽度
::-webkit-scrollbar {
  // width: 1px;
}
// 滚动条滑槽样式
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
// 滚动条样式
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 168, 255, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
::-webkit-scrollbar-thumb:active {
  background: #999;
}
// 浏览器失焦的样式
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.page-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
