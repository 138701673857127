body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
hr {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
label {
  font-weight: 700;
}
html {
  height: 100%;
  box-sizing: border-box;
}
#app {
  height: 100%;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
.no-padding {
  padding: 0px !important;
}
.padding-content {
  padding: 4px 0;
}
a:focus,
a:active {
  outline: none;
}
a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
button,
div:focus {
  outline: none;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.pr-5 {
  padding-right: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.block {
  display: block;
}
.pointer {
  cursor: pointer;
}
.inlineBlock {
  display: inline-block;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
code {
  background: #eef1f6;
  padding: 15px 16px;
  margin-bottom: 20px;
  display: block;
  line-height: 36px;
  font-size: 15px;
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif;
}
code a {
  color: #337ab7;
  cursor: pointer;
}
code a:hover {
  color: #20a0ff;
}
@font-face {
  font-family: DINEngschrift;
  src: url('../assets/fonts/dinengschrift_1_.ttf');
}
@font-face {
  font-family: FZLTZHK;
  src: url('../assets/fonts/fzltzhgbk.ttf');
}
body {
  font-size: 14px;
  font-size: '微端雅黑';
  min-width: 1440px;
}
#root {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 168, 255, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}
::-webkit-scrollbar-thumb:active {
  background: #999;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
.page-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
